import React from "react";
import Options from "./options";
import Logo from "../../assets/logo.png";

import {
  Container,
  LogoImage,
  OptionsContainer,
} from './styles'

export default function Header({ scroll }) {
  return (
    <Container>
      <LogoImage>
        <img style={{ marginTop: 38 }} src={Logo} alt="logo" />
      </LogoImage>
      <OptionsContainer>
        <Options  border={scroll < 285} >
          Home
        </Options>
        <Options topScroll={285} border={scroll > 285 && scroll < 885} >
          Sobre Nós
        </Options>
        <Options topScroll={1200} border={scroll > 885 && scroll < 1500} >
          Projetos
        </Options>
        <Options topScroll={1500} border={scroll > 1500 && scroll < 2100} >
          Serviços
        </Options>
        <Options topScroll={2100} border={scroll > 2100} >
          Contato
        </Options>
      </OptionsContainer>
    </Container>
  );
}
