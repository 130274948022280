import styled from "@emotion/styled";
import BannerImage from "../../assets/bannerImage.png";
export const Container = styled.div`
    
`

export const TextBanner = styled.div`
    padding: 10% 0% 8% 0%;
    width: 100%;
    h1{
        padding: 2% 0% 0% 8%;
        font-size: 48px;
    }
    background-image: url(${BannerImage});
    background-repeat: no-repeat;
    background-position: right 0% top 120%;
    img{
        position: absolute;
        top: 35%;
        right: -15%;
    }
    @media (max-width: 600px) {
        img{
            display:none;
        }
        margin-top: 25%;
        background: none;
    }
`

export const ImageBanner = styled.div`
    padding: 12% 0% 0% 8%;
    @media (max-width: 600px) {
        img{
            display:none;
        }
    }
`