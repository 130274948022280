import styled from 'styled-components'

export const Container = styled.div`
    display: flex;
    padding: 7% 0% 0% 8%;
    hr{
        border: 1px solid red;
        width: 70px;
        margin-top: 10px;
        margin-right: 10px;
    }
`

export const Title = styled.p`
    font-weigth: 400;
`
