import React from "react";

import { Container, Title } from './style'



export default function ProjectService({ title, icon }) {
    return (
        <Container>
            <div style={{ border: 'solid 1px #fff', borderRadius: 5, padding: 15, display: 'flex' }}>
                <img src={icon} alt="icon" style={{ marginRight: 25, width: 45, height: 45, marginLeft: 80 }} className="testeService" />
                <Title>{title}</Title>
            </div>
        </Container>
    );
}
