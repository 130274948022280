import React from "react";
import Line from "../Line";

import { Container, Text, Title } from './style.js'

export default function About() {
  return (
    <>
      <Container>

        <Line text={"Conheça nossa historía"} />

        <Title>
          Sobre nossa trajetória{" "}
          <span>.</span>
        </Title>
        <Text>
          Desde 2019 nos consolidamos como quem ajuda empresas a construir
          soluções tecnologicas com uma abordagem orientada pelos melhores
          designers. Temos confiança nos processos e métodos que
          utilizamos e criamos. Contamos com equipes de design e
          desenvolvimento especialistas em produtos digitais, focadas em
          atender com agilidade e excelência.
        </Text>
        <Text>
          Estamos prontos para desenvolver todas as etapas da construção
          do produto. Seja uma aplicação móvel (web, híbrida ou nativa) ou
          desenvolvimento web ou desktop.
        </Text>
        <Text>
          Além do desenvolvimento de softwares personalizados, a Dev Flow
          também é especialista em Design Sprint, Product Discovery, entre
          outras áreas. Possui clientes e parceiros fiéis e de grande
          influência no mercado.
        </Text>
        <Text>
          Mais do que um fornecedor que entende de tecnologia, aqui na Dev
          Flow transformamos o seu negócio, somos uma software house que
          desenvolve produtos digitais e soluções tecnologicas sob medida,
          que faz total diferença desde a primeira entrega.
        </Text>
      </Container>
    </>
  );
}
