import React from "react";
import { Button, Grid, Link } from "@mui/material";
import Logo from "../../assets/DevFlow.svg";
import styled from "@emotion/styled";

import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

const ButtonEdit = styled(Button)(({ theme }) => ({
  padding: 10,
  paddingLeft: 50,
  paddingRight: 50,
}));

export default function Footer() {
  return (
    <Grid
      container
      justifyContent={"space-around"}
      alignItems="center"
      textAlign={"center"}
      style={{
        background: "rgba(193, 1, 51, 1)",
        paddingTop: "3%",
        paddingBottom: "1%",
      }}
    >
      <Grid item>
        <img style={{ width: 208, height: 138 }} src={Logo} alt="logo" />
      </Grid>
      <Grid item>
        <h2 style={{ fontWeight: 100, marginTop: "3%" }}>
          Siga-nos nas redes.
        </h2>
        <div style={{ textAlign: "left" }}>
          <p style={{ marginTop: "3%", marginBottom: "5%" }}>
            <InstagramIcon style={{ verticalAlign: "text-bottom", marginRight: 15 }} />
            <Link href="https://www.instagram.com/flowdev.code/" underline="none">
              Instagram
            </Link>
          </p>
          <p style={{ marginTop: "3%", marginBottom: "5%" }}>
            <LinkedInIcon style={{ verticalAlign: "text-bottom", marginRight: 15 }} />
            <Link href="https://www.linkedin.com/company/86417267" underline="none">
              Linkedin
            </Link>
          </p>
        </div>
      </Grid>
      <Grid item>
        <ButtonEdit variant="outlined">Fale conosco</ButtonEdit>
      </Grid>
      <Grid item xs={12}>
        <p style={{ fontWeight: 200, marginTop: "3%", padding: '1%' }}>
          © 2022 Flow Dev | Desenvolvimento Web. Todos os direitos reservados
        </p>
      </Grid>
    </Grid >
  );
}
