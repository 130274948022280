import React from "react";
import { Option } from './styles'
export default function Options({ children, border, topScroll }) {
  const scrollScreen = (top) => {
    console.log(top)
      window.scrollTo({
        top: top || 0,
        behavior: 'smooth' // Opção para rolagem suave
      });
    }
  return (
    <div>
      <Option
        onClick={() => scrollScreen(topScroll)}
        border={border}
      >
        {children}
      </Option>
    </div>
  );
}
