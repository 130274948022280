import React from "react";

import { Container, Card, Title, Description } from './style'

export default function Cards() {
  return (
    <Container>
      <Card background="rgba(29, 28, 29, 0.9)">
        <Title>
          +50 projetos<span>.</span>
        </Title>
        <Description>
          Entregues no ultimo ano
        </Description>
      </Card>
      <Card background="rgba(29, 28, 29, 0.6)">
        <Title>
          +700 horas<span>.</span>
        </Title>
        <Description>
          Desenvolvimento mensal
        </Description>
      </Card>
      <Card background="rgba(29, 28, 29, 0.2)">
        <Title>
          +300 reuniões<span>.</span>
        </Title>
        <Description>
          Entregando resultados
        </Description>
      </Card>
    </Container>
  );
}
