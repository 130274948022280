import styled from "@emotion/styled";
import { Button, Grid } from "@mui/material";
import React from "react";
import Image from '../../assets/Group 3.png'

const ButtonEdit = styled(Button)(({ theme }) => ({
  padding: 10,
  paddingLeft: 50,
  paddingRight: 50,
}));

export default function Contact() {
  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            textAlign: "center",
            background: "rgba(24, 23, 24, 1)",
            padding: 15,
            paddingBottom: "5%",
            alignItems: "center",
            backgroundImage: `url(${Image})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right -5% top 50%'
          }}
        >
          <h1 style={{ fontWeight: 100, marginTop: "3%" }}>Vamos Conversar.</h1>
          <h1 style={{ fontWeight: 800 }}>
            Possui algum projeto em mente{" "}
            <span style={{ color: "#C10133" }}>?</span>
          </h1>
          <p style={{ fontWeight: 100, marginTop: "3%", marginBottom: "5%" }}>
            Estamos aqui para te auxiliar durante todo o processo, envie nos uma
            mensagem.
          </p>
          <Grid item>
            <Grid
              container
              justifyContent={"center"}
              alignItems={"center"}
              spacing={5}
            >
              <Grid item>
                <ButtonEdit
                  onClick={(e) => {
                    window.location.href = "https://wa.me/5511976513657";
                    e.preventDefault();
                  }}
                  variant="outlined"
                >
                  WhatsApp
                </ButtonEdit>
              </Grid>
              <Grid item>
                <ButtonEdit
                  onClick={(e) => {
                    window.location.href = "mailto:devfloww@gmail.com";
                    e.preventDefault();
                  }}
                  variant="outlined"
                >
                  Email
                </ButtonEdit>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
}
