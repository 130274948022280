import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
    background: black;
    position: fixed;
    width: 97%;
    padding: 0% 2%; 
    padding-bottom: 15px;
    top: 0;
    @media (max-width: 600px) {
        display: block;
        width: 100%;
    }
    z-index:1;
`

export const LogoImage = styled.div`
    @media (max-width: 600px) {
        text-align:center;
    }
`


export const OptionsContainer = styled.div`
    display:flex;
    margin-top: 35px;
    @media (max-width: 600px) {
        padding-bottom: 15px;
        margin-left: auto;
        margin-right: auto;
    }
`

export const Option = styled.p`
    font-size: 18px;
    cursor: pointer;
    margin-left: 15px;
    margin-right: 15px;
    border-bottom: ${props => props.border ? '2px solid #C10133' : ""};
    @media (max-width: 600px) {
        font-size: 14px;
    }
`