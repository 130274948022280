import React from "react";
import Line from "../Line";
import ProjectService from "../ProjectService";

import Design from '../../assets/design.svg'
import System from '../../assets/system.svg'
import Peoples from '../../assets/peoples.svg'
import Cloud from '../../assets/cloud.svg'
import Quality from '../../assets/quality.svg'
import Integration from '../../assets/integration.svg'
import Squads from '../../assets/Squad.svg'
import Mobile from '../../assets/mobile.svg'

import {
  Container,
  ServicesCard,
  Title
} from './style'

export default function Services() {

  return (
    <>
      <Container>

        <Line text={"Conheça nossos serviços"} />

        <Title>
          Nossos serviços
          <span>.</span>
        </Title>
        <ServicesCard>
          <ProjectService title="Consultoria e Projetos de Software" icon={Peoples} />
          <ProjectService title="Desenvolvimento Software" icon={System} />
          <ProjectService title="Integração de Sistemas" icon={Integration} />
          <ProjectService title="Design UI e UX" icon={Design} />
          <ProjectService title="Cloud Computing" icon={Cloud} />
          <ProjectService title="Quality Assurance" icon={Quality} />
          <ProjectService title="Desenvolvimento de sites" icon={Mobile} />
          <ProjectService title="Alocação de squads" icon={Squads} />
        </ServicesCard>

      </Container>
    </>
  );
}
